<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">상담유형관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="">
          <div class="form-inp sm">
            <v-select
                :items="dropCustKeyItems"
                item-text="CD_NM"
                item-value="CD"
                outlined
                placeholder="선택하세요"
                v-model="ASP_NEWCUST"
                return-object
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" v-on:click="selectBtn" id="btnSearch">조회</v-btn>
        </div>
      </div>
    </div>
    <!-- 고객정보목록 -->
    <div class="box-wrap">
      <div class="d-flex">
        <div class="col-3">
          <h2 class="tit-h2 d-flex" style="position: relative">상담유형1레벨
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddLV1')" outlined class="btn-point ml-auto mr-0" @click="addLevel1" id="btnAddRow"
              style="position: absolute; right: 0"
            >추가</v-btn>
          </h2>
          <div class="mt-2">
            <v-data-table
                dense
                height="350px"
                :headers="gridDataHeaders"
                :items="gridDataText"
                :items-per-page="30"
                item-key="id"
                single-select
                :page.sync="page"
                hide-default-footer
                class="grid-default case03"
                @page-count="pageCount = $event"
                fixed-header
                @click:row="selectrow1"
                v-model="selectGroup.depth1"
                ref="table"
            >
            </v-data-table>
            <div class="grid-paging text-center pt-2">
              <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"></v-pagination>
            </div>
          </div>
        </div>
        <div class="col-3">
          <h2 class="tit-h2 d-flex">상담유형2레벨
          </h2>
          <div class="mt-2">
            <v-data-table
                dense
                height="350px"
                :headers="gridData2Headers"
                :items="gridDataText2"
                :items-per-page="30"
                item-key="id"
                :page.sync="page2"
                single-select
                hide-default-footer
                class="grid-default case03"
                @page-count="pageCount2 = $event"
                fixed-header
                v-model="selectGroup.depth2"
                @click:row="selectrow2"
                ref="table"
            >
            </v-data-table>
            <div class="grid-paging text-center pt-2">
              <v-pagination
                  v-model="page2"
                  :length="pageCount2"
                  :total-visible="totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"></v-pagination>
            </div>
          </div>
        </div>
        <div class="col-3">
          <h2 class="tit-h2 d-flex">상담유형3레벨
          </h2>
          <div class="mt-2">
            <v-data-table
                dense
                height="350px"
                :headers="gridData3Headers"
                :items="gridDataText3"
                :items-per-page="30"
                item-key="id"
                :page.sync="page3"
                single-select
                hide-default-footer
                class="grid-default case03"
                @page-count="pageCount3 = $event"
                fixed-header
                v-model="selectGroup.depth3"
                @click:row="selectrow3"
                ref="table"
            >
            </v-data-table>
            <div class="grid-paging text-center pt-2">
              <v-pagination
                  v-model="page3"
                  :length="pageCount3"
                  :total-visible="totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"></v-pagination>
            </div>
          </div>
        </div>
        <div class="col-3">
          <h2 class="tit-h2 d-flex">상담유형4레벨
          </h2>
          <div class="mt-2">
            <v-data-table
                dense
                height="350px"
                :headers="gridData4Headers"
                :items="gridDataText4"
                :items-per-page="30"
                item-key="id"
                :page.sync="page4"
                single-select
                hide-default-footer
                class="grid-default case03"
                @page-count="pageCount4 = $event"
                fixed-header
                v-model="selectGroup.depth4"
                @click:row="selectrow4"
                ref="table"
            >
            </v-data-table>
            <div class="grid-paging text-center pt-2">
              <v-pagination
                  v-model="page4"
                  :length="pageCount4"
                  :total-visible="totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"></v-pagination>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--// 고객정보목록 -->
    <!-- 고객상세정보 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">상담유형설정
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAdd')" outlined class="btn-default" v-on:click="addNextLevel" id="btnNew">추가</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" v-on:click="deleteRtn" id="btnDelete">삭제</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" v-on:click="processRtn" id="btnSave">저장</v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-form ref="form"
                v-model="valid"
                lazy-validation
        >
          <div class="table-form">
            <table>
              <caption class="hide">상담유형</caption>
              <colgroup>
                <col width="127px">
                <col width="291px">
                <col width="127px">
                <col width="291px">
                <col width="127px">
                <col width="291px">
                <col width="127px">
                <col width="">
              </colgroup>
              <tbody>
              <tr>
                <th scope="row">
                  <v-badge dot color="#EF9191">회사명</v-badge>
                </th>
                <td>
                  <v-text-field
                      class="form-inp full"
                      name="name"
                      id="ASP_NEWCUST_KEY_NM"
                      outlined
                      v-model="ASP_NEWCUST_KEY_NM"
                      :rules="validateRules.aspNewCustKey"
                      readonly
                      hide-details="auto"
                      required>
                  </v-text-field>
                </td>
                <th scope="row">
                  <v-badge dot color="#EF9191">상담유형 상위코드</v-badge>
                </th>
                <td>
                  <v-text-field
                      class="form-inp full"
                      name="name"
                      id="id" outlined
                      v-model="SPST_CNSL_TYP_CD"
                      :rules="validateRules.spstCnslTypCd"
                      readonly
                      hide-details="auto"
                      required>
                  </v-text-field>
                </td>
                <th scope="row">
                  <v-badge dot color="#EF9191">상담유형코드</v-badge>
                </th>
                <td>
                  <v-text-field
                      class="form-inp full"
                      name="name"
                      id="id" outlined
                      v-model="CNSL_TYP_CD"
                      :rules="validateRules.cnslTypCd"
                      :readonly="clickAddBtnYn"
                      hide-details="auto"
                      required>
                  </v-text-field>
                </td>
                <th scope="row">
                  <v-badge dot color="#EF9191">상담유형명</v-badge>
                </th>
                <td>
                  <v-text-field
                      class="form-inp full"
                      name="name"
                      id="id" outlined
                      v-model="CNSL_TYP_NM"
                      :rules="validateRules.cnslTypNm"
                      hide-details="auto"
                      required>
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <v-badge dot color="#EF9191">사용여부</v-badge>
                </th>
                <td>
                  <div class="form-inp full">
                    <v-select
                        :items="dropYnItems"
                        item-text="name"
                        outlined
                        placeholder="사용여부"
                        v-model="USE_YN"
                        :rules="validateRules.useYn"
                        hide-details="auto"
                        required
                    ></v-select>
                  </div>
                </td>
                <th scope="row">
                  <v-badge dot color="#EF9191">상담유형 정렬순서</v-badge>
                </th>
                <td colspan="5">
                  <v-text-field
                      class="form-inp full mt-0"
                      type="number"
                      v-model="SORT_ORD"
                      id="sortOrd"
                      min="0"
                      max="9999"
                      :rules="validateRules.sortOrd"
                      hide-details="auto"
                      required
                  ></v-text-field>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/store/apiUtil";
import {mixin} from "@/mixin/mixin";
import alertStore from "@/store/modules/alertStore";

export default {
  name: "MENU_M810101", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  data() {
    return {

      flag          : true, //추가버튼시 레벨이 1번만 올라가는용
      clickAddBtnYn   : true,
      TRANS_STATUS  : '',
      HEADER_SERVICE: 'setting.consulttype.manage',
      HEADER_TYPE   : 'BIZ_SERVICE',

      URLData: '',

      //select box
      dropCustKeyItems  : [],
      dropYnItems: [
        {name: "사용", value: "Y"},
        {name: "미사용", value: "N"},
      ],

      //grid 설정
      headers         : {},
      sendData        : {},
      gridDataHeaders : [
        {
          text    : 'No',
          align   : 'center',
          value   : 'index',
          width   : '50px',
          sortable: true,
        },
        {text: '코드', value: 'CNSL_TYP_CD', align: 'center', width: '60px', sortable: true,},
        {text: '상담유형명', value: 'CNSL_TYP_NM', align: 'left', sortable: true,},
        {text: '사용여부', value: 'USE_YN', width: '60px', align: 'center', sortable: true,},

      ],
      gridData2Headers: [
        {
          text    : 'No',
          align   : 'center',
          value   : 'index',
          width   : '50px',
          sortable: true,
        },
        {text: '코드', value: 'CNSL_TYP_CD', align: 'center', width: '60px', sortable: true,},
        {text: '상담유형명', value: 'CNSL_TYP_NM', align: 'left', sortable: true,},
        {text: '사용여부', value: 'USE_YN', width: '60px', align: 'center', sortable: true,},
      ],
      gridData3Headers: [
        {
          text    : 'No',
          align   : 'center',
          value   : 'index',
          width   : '50px',
          sortable: true,
        },
        {text: '코드', value: 'CNSL_TYP_CD', align: 'center', width: '60px', sortable: true,},
        {text: '상담유형명', value: 'CNSL_TYP_NM', align: 'left', sortable: true,},
        {text: '사용여부', value: 'USE_YN', width: '60px', align: 'center', sortable: true,},
      ],
      gridData4Headers: [
        {
          text    : 'No',
          align   : 'center',
          value   : 'index',
          width   : '50px',
          sortable: true,
        },
        {text: '코드', value: 'CNSL_TYP_CD', align: 'center', width: '60px', sortable: true,},
        {text: '상담유형명', value: 'CNSL_TYP_NM', align: 'left', sortable: true,},
        {text: '사용여부', value: 'USE_YN', width: '60px', align: 'center', sortable: true,},
      ],

      selectGroup: {depth1: [], depth2: [], depth3: [], depth4: []},

      gridDataText: [],
      page        : 1,
      pageCount   : 0,
      itemsPerPage: 5,
      totalVisible: 10,

      gridDataText2: [],
      page2        : 1,
      pageCount2   : 0,
      itemsPerPage2: 5,

      gridDataText3: [],
      page3        : 1,
      pageCount3   : 0,
      itemsPerPage3: 5,

      gridDataText4: [],
      page4        : 1,
      pageCount4   : 0,
      itemsPerPage4: 5,


      //v-model
      ASP_NEWCUST_KEY   : '',
      CNSL_TYP_CD       : '',
      CNSL_TYP_NM       : '',
      USE_YN            : '',
      SPST_CNSL_TYP_CD  : '',
      SORT_ORD          : '',
      ASP_NEWCUST_KEY_NM: '',
      ASP_NEWCUST       : '',

      //메세지
      alertMsg: {
        selBoxBizChk: '회사구분 은(는) 필수입력입니다.',
        noCntData   : '조회된 데이터가 없습니다.',
        newWriteChk : '조회 후 작성 가능합니다.',
        clickRow    : '선택된 값이 없습니다. 클릭 후 추가 가능합니다.'
      },

      //valid관련 데이터
      valid        : true,
      validateRules: {
        aspNewCustKey: [
          v => !!v || '회사선택은 필수입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        spstCnslTypCd: [
          v => !!v || '코드는 필수입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        cnslTypCd    : [
          v => !!v || '상담유형코드는 필수입니다.',
        ],
        cnslTypNm    : [
          v => !!v || '상담유형명은 필수입니다.',
        ],
        sortOrd      : [
          //v => !!v || '정렬번호는 필수입니다.',
          v => /^[0-9]*$/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
          v => (v >= 0 && v < 10000) || '0 ~ 9999 이내로 입력해 주세요.',
        ],
        useYn        : [
          v => !!v || '사용여부는 필수입니다.',
        ]
      }
    }

  },
  mounted() {
    this.dropCustKeyItems = this.$store.getters['userStore/GE_USER_COMPANY'];

  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    //Table row 클릭이벤트
    activeRow(row, value) {
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

    showAlert() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg              : '회사구분란에서 회사를 선택후 이용해주십시오',
        iconClass        : 'svg-error-lg',
        type             : 'default',
      })
    },

    //조회
    async selectBtn() {
      this.ASP_NEWCUST_KEY = this.ASP_NEWCUST.CD
      this.ASP_NEWCUST_KEY_NM = this.ASP_NEWCUST.CD_NM
      let companyKey = this.ASP_NEWCUST_KEY;
      if (companyKey) {
        // 헤더 초기화 및 세팅
        this.headers = this.initHeaders;
        this.URLData = "/api/setting/consulttype/manage/level1/inqire";
        this.headers["METHOD"] = "inqire";
        this.headers["GRID_ID"] = "divGridCnslTypLevel1"
        this.TRANS_STATUS = "I"

        // sendData 초기화 및 세팅
        this.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;

        // 페이징 초기화 및 세팅
        this.gridDataText = [];
        this.gridDataText2 = [];
        this.gridDataText3 = [];
        this.gridDataText4 = [];
        this.selectGroup.depth1 = [];
        this.selectGroup.depth2 = [];
        this.selectGroup.depth3 = [];
        this.selectGroup.depth4 = [];

        this.result();
      } else {
        this.showAlert();
      }

    },
    getListCallBack(response) {
      let data = response.DATA;
      let header = response.HEADER;

      if(!this.mixin_isEmpty(response) && !header.ERROR_FLAG){
        if(!this.mixin_isEmpty(data) && data.length > 0) {
          for (let d of data) {
            for (let k of Object.keys(d)) {
              d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k];
            }
            d['id'] = d.CNSL_TYP_CD + this.gridDataText.length + 1;
            d['index'] = this.gridDataText.length + 1;
            this.gridDataText.push(d);
          }
        }
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }

    },

    selectrow1(item, row) {
      if (item != null) {
        this.activeRow(row, true);
      }
      this.nextDept(item);
    },

    nextDept(item) {
      this.clickAddBtnYn = true;
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/consulttype/manage/list";
      this.headers["METHOD"] = "list";
      this.headers["GRID_ID"] = "divGridCnslTypLevel1"


      //sendData 초기화 및 세팅
      this.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      this.sendData["CNSL_TYP_CD"] = item.CNSL_TYP_CD

      // 페이징 초기화 및 세팅
      this.page2 = 1;
      this.pageCount2 = 0;
      this.itemsPerPage2 = 5;
      this.totalVisible = 10;
      this.gridDataText2 = [];
      this.gridDataText3 = [];
      this.gridDataText4 = [];
      this.selectGroup.depth2 = [];
      this.selectGroup.depth3 = [];
      this.selectGroup.depth4 = [];

      this.USE_YN = item.USE_YN;
      this.CNSL_TYP_CD = item.CNSL_TYP_CD;
      this.CNSL_TYP_NM = item.CNSL_TYP_NM;
      this.CNSL_TYP_DIV_CD = item.CNSL_TYP_DIV_CD;
      this.SPST_CNSL_TYP_CD = item.SPST_CNSL_TYP_CD;
      this.TEMPLATE_ID = item.TEMPLATE_ID;
      this.SORT_ORD = item.SORT_ORD;
      this.TRANS_STATUS = 'U';

      this.result();

    },
    getDetailCallBack(response) {
      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG){
        let data = response.DATA;
        if(!this.mixin_isEmpty(data) && data.length > 0) {
          for (let d of data) {
            for (let k of Object.keys(d)) {
              d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k];
            }
            d['id'] = d.CNSL_TYP_CD + this.gridDataText2.length + 1;
            d['index'] = this.gridDataText2.length + 1;
            this.gridDataText2.push(d);
          }
        }
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }
    },

    selectrow2(item, row) {
      if (item != null) {
        this.activeRow(row, true);
      }
      this.nextDept2(item);
    },
    nextDept2(item) {
      this.clickAddBtnYn = true;
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/consulttype/manage/list";
      this.headers["METHOD"] = "list";
      this.headers["GRID_ID"] = "divGridCnslTypLevel2"

      //sendData 초기화 및 세팅
      this.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      this.sendData["CNSL_TYP_CD"] = item.CNSL_TYP_CD

      // 페이징 초기화 및 세팅
      this.page3 = 1;
      this.pageCount3 = 0;
      this.itemsPerPage3 = 5;
      this.totalVisible = 10;
      this.gridDataText3 = [];
      this.gridDataText4 = [];
      this.selectGroup.depth3 = [];
      this.selectGroup.depth4 = [];

      this.USE_YN = item.USE_YN;
      this.CNSL_TYP_CD = item.CNSL_TYP_CD;
      this.CNSL_TYP_NM = item.CNSL_TYP_NM;
      this.CNSL_TYP_DIV_CD = item.CNSL_TYP_DIV_CD;
      this.SPST_CNSL_TYP_CD = item.SPST_CNSL_TYP_CD;
      this.TEMPLATE_ID = item.TEMPLATE_ID;
      this.SORT_ORD = item.SORT_ORD;
      this.TRANS_STATUS = 'U';

      this.result();

    },
    getDetailCallBack2(response) {

      let data = response.DATA;
      let header = response.HEADER;

      if(!this.mixin_isEmpty(response) && !header.ERROR_FLAG){
        if(!this.mixin_isEmpty(data) && data.length > 0) {
          for (let d of data) {
            for (let k of Object.keys(d)) {
              d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k];
            }
            d['id'] = d.CNSL_TYP_CD + this.gridDataText3.length + 1;
            d['index'] = this.gridDataText3.length + 1;
            this.gridDataText3.push(d);
          }
        }
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }

    },

    selectrow3(item, row) {
      if (item != null) {
        this.activeRow(row, true);
      }
      this.nextDept3(item);
    },
    nextDept3(item) {
      this.clickAddBtnYn = true;
      this.headers = this.initHeaders;

      this.URLData = "/api/setting/consulttype/manage/list";
      this.headers["METHOD"] = "list";
      this.headers["GRID_ID"] = "divGridCnslTypLevel3"

      //sendData 초기화 및 세팅
      this.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      this.sendData["CNSL_TYP_CD"] = item.CNSL_TYP_CD

      // 페이징 초기화 및 세팅
      this.page4 = 1;
      this.pageCount4 = 0;
      this.itemsPerPage4 = 5;
      this.totalVisible = 10;
      this.gridDataText4 = [];
      this.selectGroup.depth4 = [];

      this.USE_YN = item.USE_YN;
      this.CNSL_TYP_CD = item.CNSL_TYP_CD;
      this.CNSL_TYP_NM = item.CNSL_TYP_NM;
      this.CNSL_TYP_DIV_CD = item.CNSL_TYP_DIV_CD;
      this.SPST_CNSL_TYP_CD = item.SPST_CNSL_TYP_CD;
      this.TEMPLATE_ID = item.TEMPLATE_ID;
      this.SORT_ORD = item.SORT_ORD;
      this.TRANS_STATUS = 'U';

      this.result();

    },
    getDetailCallBack3(response) {

      let data = response.DATA;
      let header = response.HEADER;

      if(!this.mixin_isEmpty(response) && !header.ERROR_FLAG){
        if(!this.mixin_isEmpty(data) && data.length > 0) {
          for (let d of data) {
            for (let k of Object.keys(d)) {
              d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k];
            }
            d['id'] = d.CNSL_TYP_CD + this.gridDataText4.length + 1;
            d['index'] = this.gridDataText4.length + 1;
            this.gridDataText4.push(d);
          }
        }
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }

    },

    selectrow4(item, row) {

      if (item != null) {
        this.activeRow(row, true);
      }
      this.nextDept4(item);
    },
    nextDept4(item) {
      this.clickAddBtnYn = true;

      this.headers = this.initHeaders;

      this.URLData = "/api/setting/consulttype/manage/list";
      this.headers["METHOD"] = "list";
      this.headers["GRID_ID"] = "divGridCnslTypLevel4"

      //sendData 초기화 및 세팅
      this.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      this.sendData["CNSL_TYP_CD"] = item.CNSL_TYP_CD

      let idx = 1;
      for (let i in this.gridDataText4) {
        this.gridDataText4[i]["index"] = idx++;
      }

      this.USE_YN = item.USE_YN;
      this.CNSL_TYP_CD = item.CNSL_TYP_CD;
      this.CNSL_TYP_NM = item.CNSL_TYP_NM;
      this.CNSL_TYP_DIV_CD = item.CNSL_TYP_DIV_CD;
      this.SPST_CNSL_TYP_CD = item.SPST_CNSL_TYP_CD;
      this.TEMPLATE_ID = item.TEMPLATE_ID;
      this.SORT_ORD = item.SORT_ORD;
      this.TRANS_STATUS = 'U';
    },

    //삭제버튼
    async deleteRtn(item) {
      if (this.selectGroup.depth1.length == 0) {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg              : "체크된 항목이 존재하지 않습니다.",
          iconClass        : "svg-error-lg",
          type             : "default",
        });
        return;
      }

      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg              : '정말 삭제하시겠습니까?',
        iconClass        : 'svg-error-lg',
        type             : 'confirm',
        callYes          : this.callYes,
        callNo           : this.callNo,
      })


    },
    callYes() {
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/consulttype/manage/delete";
      this.headers["METHOD"] = "delete";

      //sendData 초기화 및 세팅
      this.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      this.sendData["CNSL_TYP_CD"] = this.CNSL_TYP_CD;
      this.sendData["CNSL_TYP_DIV_CD"] = this.CNSL_TYP_DIV_CD;

      this.result();

      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });

      this.common_alert('정상 처리 되었습니다.', 'done')


    },
    callNo() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    getDeleteCallBack(response) {

      let header = response.HEADER;

      let data = response.DATA;

      //재조회
      if (header.ERROR_FLAG == false) {
        this.selectBtn();
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }

      //초기화
      this.$refs.form.resetValidation();

      //상담유형설정 초기화
      this.SORT_ORD = '';
      this.USE_YN = '';
      this.CNSL_TYP_NM = '';
      this.CNSL_TYP_CD = '';

    },

    async result() {
      //api 호출 부분
      await api.post(this.URLData,   //api url입력
          //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
          this.sendData
          , //api에서 사용될 data 입력
          {
            head: this.headers
          }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
          .then((response) => {
            if (typeof (response.data) === "string") {
              let responseData = JSON.parse(response.data.replaceAll("\n", ""));
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: true,
                msg              : responseData.HEADER.ERROR_MSG,
                iconClass        : "svg-error-lg",
                type             : "default",
              });
              return;
            }

            if (response.data.HEADER.ERROR_FLAG) {
              let msg = response.data.HEADER.ERROR_MSG;
              if(response.data.HEADER.ERROR_MSG == "MCS에 해당 상담유형코드가 이미 존재합니다."){
                msg = "이미 동일한 유형코드가 존재합니다.";
              }
              this.common_alert(msg, "error");
              return;
            }


            if (response.data.HEADER.METHOD == "inqire") {
              this.getListCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "list" && response.data.HEADER.GRID_ID == "divGridCnslTypLevel1") {
              this.getDetailCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "list" && response.data.HEADER.GRID_ID == "divGridCnslTypLevel2") {
              this.getDetailCallBack2(response.data);
            } else if (response.data.HEADER.METHOD == "list" && response.data.HEADER.GRID_ID == "divGridCnslTypLevel3") {
              this.getDetailCallBack3(response.data);
            } else if (response.data.HEADER.METHOD == "regist") {
              this.getInsertCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "delete") {
              this.getDeleteCallBack(response.data);
            }

          }) //api 호출 성공 이후 수행
          .catch((err) => {
            alert(err);
          }) //api호출 에러 작업 수행

    },

    addLevel1() {
      if (this.gridDataText.length == 0 || this.dropCustKeyItems == '') {
        this.common_alert(this.alertMsg.newWriteChk, "error");
        return;
      }
      //초기화
      this.$refs.form.resetValidation();
      this.clickAddBtnYn = false;
      this.USE_YN = '';
      this.CNSL_TYP_CD = '';
      this.CNSL_TYP_NM = '';
      this.CNSL_TYP_DIV_CD = '1';
      this.SPST_CNSL_TYP_CD = '*';
      this.TEMPLATE_ID = '';
      this.TRANS_STATUS = 'I';
      this.SORT_ORD = '';
    },

    //추가 버튼
    addNextLevel() {
      if (this.gridDataText.length == 0 || this.dropCustKeyItems == '') {
        this.common_alert(this.alertMsg.newWriteChk, "error");
        return;
      } else if (this.SPST_CNSL_TYP_CD.length == 0) {
        this.common_alert(this.alertMsg.clickRow, "error");
        return;
      }
      //초기화
      this.$refs.form.resetValidation();

      this.clickAddBtnYn = false;

      let NextCnslCd = this.CNSL_TYP_DIV_CD

      if (this.flag) {
        NextCnslCd = parseInt(this.CNSL_TYP_DIV_CD) + 1;
        this.flag = false;
      } else {
        NextCnslCd = parseInt(this.CNSL_TYP_DIV_CD);
      }

      this.USE_YN = '';
      this.SPST_CNSL_TYP_CD = this.CNSL_TYP_CD;
      this.CNSL_TYP_DIV_CD = NextCnslCd.toString();
      this.CNSL_TYP_CD = '';
      this.CNSL_TYP_NM = '';
      this.SORT_ORD = '';
      this.TRANS_STATUS = 'I';

    },

    //저장 이벤트
    async processRtn() {

      if (!this.validate()) {
        return;
      }

      // 헤더 초기화 및 세팅
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/consulttype/manage/regist";
      this.headers["METHOD"] = "regist";

      // sendData 초기화 및 세팅
      this.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      this.sendData["REGR_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      this.sendData["REGR_DEPT_CD"] = "101010";
      this.sendData["SPST_CNSL_TYP_CD"] = this.SPST_CNSL_TYP_CD;
      this.sendData["CNSL_TYP_CD"] = this.CNSL_TYP_CD;
      this.sendData["CNSL_TYP_NM"] = this.CNSL_TYP_NM;
      this.sendData["USE_YN"] = this.USE_YN;
      this.sendData["SORT_ORD"] = this.SORT_ORD;
      this.sendData["CNSL_TYP_DIV_CD"] = this.CNSL_TYP_DIV_CD;
      this.sendData["TRANS_STATUS"] = this.TRANS_STATUS;
      this.sendData["TEMPLATE_ID"] = '';

      this.result();

    },
    getInsertCallBack(response) {
      let header = response.HEADER;

      let data = response.DATA;

      //alert창 설정
      let iconType = 'done';
      let msg = "정상 처리 되었습니다.";

      if (header.ERROR_FLAG == false) {
        //alert창 띄우기
        this.common_alert(msg, iconType);
        this.selectBtn();
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }



      //상담유형설정 초기화
      this.SORT_ORD = '';
      this.USE_YN = '';
      this.CNSL_TYP_NM = '';
      this.CNSL_TYP_CD = '';
      this.$refs.form.resetValidation();

    },

  },

  computed: {
    initHeaders() {
      return this.headers = {
        "SERVICE": this.HEADER_SERVICE,
        "METHOD" : "",
        "TYPE"   : this.HEADER_TYPE,
        "GRID_ID": this.GRID_ID,
      };
    }

  },
}
</script>